.canvasContain {
    position: relative;
    width: 329px;
    height: 329px;
    margin: auto;
}

#myCanvas {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
}

.canvasContain img {
    position: absolute;
    width: 329px;
    height: 329px;
    margin: auto;
    left: 0;
    right: 0;

}

.label {
    position: absolute;
    border: 1px solid #D8D8D8;
    border-radius: 27.5px;
    width: max-content !important;
    font-size: 11px;
     padding: 4px 8px;
}

.label0 {
    top: 88px;
    left: 320px;
}
.label1 {
    top: 226px;
    left: 320px;
}
.label2 {
    top: 315px;
    left: 205px;
}
.label3 {
    top: 315px;
    left: 68px;
}
.label4 {
    top: 226px;
    left: -65px;
} 
.label5 {
    top: 81px;
    left: -40px;

}
.label6 {
    top: -6px;
    left: 54px;
}
.label7 {
    top: -6px;
    left: 215px;
}

@media (max-width: 480px) {
    .canvasContain, .canvasContain img, #myCanvas {
        width: 180px;
        height: 180px;
    }

    .label {
        font-size: 10px;
    }

    .label0 {
        top: 40px;
        left: 175px;
    }
    .label1 {
        top: 120px;
        left: 175px;
    }
    .label2 {
        top: 175px;
        left: 110px;
    }
    .label3 {
        top: 175px;
        left: 30px;
    }
    .label4 {
        top: 126px;
        left: -60px;
    }
    .label5 {
        top: 44px;
        left: -42px;
    }
    .label6 {
        top: -16px;
        left: -10px;
    }
    .label7 {
        top: -16px;
        left: 115px;
    }
}

.selfy-img {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0;
    background: white;
}

.selfy-img .num {
    display: block;
    margin: 60px auto 30px;
    color: #5B6577;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
}

.selfy-img .treatment-analysis {
    display: block;
    margin: auto;
    width: fit-content;
    color: #5B6577;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
}

.selfy-img .treatment-name {
    border: 0;
    padding: 8px 16px;
    margin: 30px auto;
    display: block;
    font-size: 24px !important;
    background: none;
    color: black;
}

progress[value] {
    width: 250px;
    height: 35px;
    margin: auto;
    display: block;
}

.progress-bar {
    border-radius: 2px;
    width: 250px;
    height: 22px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    padding: 4px 0;
    text-align: center;
}

.progress-bar > span {
    color: white;
    border-radius: 2px;
    font-size: 10px;
}

.photo-container {
    width: 100%;
    margin: auto;
    position: relative;
    max-width: 500px;
}

.photo-container img {
    position: absolute;
    width: 100%;
}

.in-scan {
    margin: 60vh auto 0;
    text-align: center;
    display: block;
}
